import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import CountryFlag from "vue-country-flag";
import store from "./store.js";

import VueI18n from "vue-i18n";
Vue.use(VueI18n);

import Carousel3d from "vue-carousel-3d";
Vue.use(Carousel3d);

Vue.component("country-flag", CountryFlag);

import VueCookies from "vue-cookies";
Vue.use(VueCookies);

Vue.$cookies.config("3600d");

Vue.config.productionTip = false;

window.axios = require("axios");

window.vinaApi = axios.create({
  baseURL: "https://api.master-fb.com/",
  // baseURL: "http://localhost:8000/",
  withCredentials: true,
  headers: {
    Accept: "application/json",
  },
});

const messages = {
  en: {
    cancel: "Cancel",
    add_note: "Add note",
    filters: "Filters",
    back: "Back",
    winery: "Winery",
    region: "Region",
    alcohol: "Alcohol percentage",
    sort: "Sort",
    legal: "Legal information",
    menu: "Choose menu",
    products: "No products",
    close: "Close",
    companyName: "Company name",
    address: "Address",
    complaints: "Book of complaints is located at the bar.",
    serving: "Way of serving",
    glass: "Glass",
    regions: "Regions",
    confirm: "Confirm",
    clear: "Clear filters",
    adInst: "Click anywhere to close ad",
    sulphits:
      "In all our wines be high in the labeling enquierment  allergen O-Sulphits",
    alergens:
      "Allergeninformation gemäß Codex-Empfehlung: A-glutenhaltiges Getreide; B-Krebstiere; C-Ei, D-Fisch; E-Erdnuss; F-Soja;\n" +
      "G-Milch oder Laktose; H-Schalenfrüchte; L-Sellerie; M-Senf; N-Sesam; O-Sulfite; P-Lupinen, R-Weichtiere",
    all_wines: "All wines",
    on_glass: "By the glass",
    food_class: " Food classification",
    wine: "Wines",
    search: "Search",
    menu1: "Menu",
    preporuka: "Sommelier's recommendation",
    type_of_drinks: "Type of drink",
    premium_water: "Premium water",
    description: "Description",
    price: "Price",
    order: "Order",
    table_number: "Table number",
    note: "Remark",
    glass_1: "Glass",
    bottle: "Bottle",
    order_successful: "Order successful!",
    order_reviewed: "Order reviewed!",
    mark_order: "Mark order as reviewed!",
    review: "Viewed",
    order_num: "Order number:",
    table_num: "Table number:",
    amount: "Amount:",
    review_orders: "Order review",
    password: "Password",
    view_your_orders: "View your orders",
    view_all_orders: "View all orders",
    payment_method: "Payment method",
    credit_card: "Credit card",
    cash: "Cash",
    room: "Room",
    choose_card: "Choose card",
    all_products: "All products",
  },
  hr: {
    cancel: "Odustani",
    add_note: "Dodajte napomenu",
    filters: "Filteri",
    back: "Nazad",
    winery: "Vinarija",
    region: "Regija",
    alcohol: "Postotak alkohola",
    sort: "Sorta",
    legal: "Pravne informacije",
    menu: "Odaberite meni",
    products: "Nema proizvoda",
    close: "Zatvori",
    companyName: "Naziv firme",
    address: "Adresa",
    complaints: "Knjiga žalbe nalazi se na šanku",
    serving: "Način serviranja",
    glass: "Na čašu",
    regions: "Regije",
    confirm: "Potvrdi",
    clear: "Očisti filtere",
    adInst: "Kliknite bilo gdje kako bi ste pregledali meni",
    // adInst: 'Kliknite bilo gdje kako biste zatvorili reklamu',
    all_wines: "Sva vina",
    on_glass: "Na čašu",
    food_class: "Podjela hrane",
    wine: "Vina",
    search: "Pretraga",
    menu1: "Menu",
    preporuka: "Preporuka Sommeliera",
    type_of_drinks: "Vrste pića",
    premium_water: "Premium voda",
    description: "Opis",
    price: "Cijena",
    order: "Naruči",
    table_number: "Broj stola",
    note: "Napomena",
    glass_1: "Čaša",
    bottle: "Boca",
    order_successful: "Uspješna narudžba!",
    order_reviewed: "Narudžba pregledana!",
    mark_order: "Označi narudžbu kao pregledanu!",
    review: "Pregledano",
    order_num: "Narudžba broj:",
    table_num: "Stol broj:",
    amount: "Količina:",
    review_orders: "Pregled narudžbi",
    password: "Lozinka",
    view_your_orders: "Pogledaj svoje narudžbe",
    view_all_orders: "Pregled svih narudžbi",
    payment_method: "Način plaćanja",
    credit_card: "Kreditna kartica",
    cash: "Gotovina",
    room: "Soba",
    choose_card: "Odaberite karticu",
    all_products: "Svi proizvodi",
  },
  ba: {
    cancel: "Odustani",
    add_note: "Dodajte napomenu",
    filters: "Filteri",
    back: "Nazad",
    winery: "Vinarija",
    region: "Regija",
    alcohol: "Postotak alkohola",
    sort: "Sorta",
    legal: "Pravne informacije",
    menu: "Odaberite meni",
    products: "Nema proizvoda",
    close: "Zatvori",
    companyName: "Naziv firme",
    address: "Adresa",
    complaints: "Knjiga žalbe nalazi se na šanku",
    serving: "Način serviranja",
    glass: "Na čašu",
    regions: "Regije",
    confirm: "Potvrdi",
    clear: "Očisti filtere",
    adInst: "Kliknite bilo gdje kako bi ste pregledali meni",
    // adInst: 'Kliknite bilo gdje kako biste zatvorili reklamu'
    all_wines: "Sva vina",
    on_glass: "Na čašu",
    food_class: "Podjela hrane",
    wine: "Vina",
    search: "Pretraga",
    menu1: "Menu",
    preporuka: "Preporuka Sommeliera",
    type_of_drinks: "Vrste pića",
    premium_water: "Premium voda",
    description: "Opis",
    price: "Cijena",
    order: "Naruči",
    table_number: "Broj stola",
    note: "Napomena",
    glass_1: "Čaša",
    bottle: "Boca",
    order_successful: "Uspješna narudžba!",
    order_reviewed: "Narudžba pregledana!",
    mark_order: "Označi narudžbu kao pregledanu!",
    review: "Pregledano",
    order_num: "Narudžba broj:",
    table_num: "Stol broj:",
    amount: "Količina:",
    review_orders: "Pregled narudžbi",
    password: "Lozinka",
    view_your_orders: "Pogledaj svoje narudžbe",
    view_all_orders: "Pregled svih narudžbi",
    payment_method: "Način plaćanja",
    credit_card: "Kreditna kartica",
    cash: "Gotovina",
    room: "Soba",
    choose_card: "Odaberite karticu",
    all_products: "Svi proizvodi",
  },
  sr: {
    cancel: "Odustani",
    add_note: "Dodajte napomenu",
    filters: "Filteri",
    back: "Nazad",
    winery: "Vinarija",
    region: "Regija",
    alcohol: "Postotak alkohola",
    sort: "Sorta",
    legal: "Pravne informacije",
    menu: "Odaberite meni",
    products: "Nema proizvoda",
    close: "Zatvori",
    companyName: "Naziv firme",
    address: "Adresa",
    complaints: "Knjiga žalbe nalazi se na šanku",
    serving: "Način serviranja",
    glass: "Na čašu",
    regions: "Regije",
    confirm: "Potvrdi",
    clear: "Očisti filtere",
    adInst: "Kliknite bilo gdje kako bi ste pregledali meni",
    // adInst: 'Kliknite bilo gdje kako biste zatvorili reklamu',
    all_wines: "Sva vina",
    on_glass: "Na čašu",
    food_class: "Podjela hrane",
    wine: "Vina",
    search: "Pretraga",
    menu1: "Menu",
    preporuka: "Preporuka Sommeliera",
    type_of_drinks: "Vrste pića",
    premium_water: "Premium voda",
    description: "Opis",
    price: "Cijena",
    order: "Naruči",
    table_number: "Broj stola",
    note: "Napomena",
    glass_1: "Čaša",
    bottle: "Boca",
    order_successful: "Uspješna narudžba!",
    order_reviewed: "Narudžba pregledana!",
    mark_order: "Označi narudžbu kao pregledanu!",
    review: "Pregledano",
    order_num: "Narudžba broj:",
    table_num: "Stol broj:",
    amount: "Količina:",
    review_orders: "Pregled narudžbi",
    password: "Lozinka",
    view_your_orders: "Pogledaj svoje narudžbe",
    view_all_orders: "Pregled svih narudžbi",
    payment_method: "Način plaćanja",
    credit_card: "Kreditna kartica",
    cash: "Gotovina",
    room: "Soba",
    choose_card: "Odaberite karticu",
    all_products: "Svi proizvodi",
  },
  de: {
    cancel: "Abbrechen",
    add_note: "Füg ein Notiz hinzu",
    filters: "Filter",
    back: "Zurück",
    winery: "Weingut",
    region: "Region",
    alcohol: "Alkohol",
    sort: "Sorte",
    legal: "Rechtliche Informationen",
    menu: "Menü auswählen",
    products: "Keine Produkte",
    close: "Schließen",
    companyName: "Firmenname",
    address: "Adresse",
    complaints: "Das Beschwerdebuch befindet sich an der Bar",
    serving: "Serviermethode",
    glass: "Glas",
    regions: "Regionen",
    confirm: "Bestätigen",
    clear: "Filter löschen",
    adInst:
      "Klicken Sie auf eine beliebige Stelle, um die Anzeige zu schließen.",
    sulphits:
      "Alle unsere Weine enthalten das zu Kennzeichnungspflichtige allergen O-Sulfite",
    alergens:
      "Allergeninformation gemäß Codex-Empfehlung: A-glutenhaltiges Getreide; B-Krebstiere; C-Ei, D-Fisch; E-Erdnuss; F-Soja;\n" +
      "G-Milch oder Laktose; H-Schalenfrüchte; L-Sellerie; M-Senf; N-Sesam; O-Sulfite; P-Lupinen, R-Weichtiere",
    all_wines: "Alle Weine",
    on_glass: "Im Glas",
    food_class: "Lebensmittel Klassifizierung",
    wine: "Wein",
    search: "Suche",
    menu1: "Menu",
    preporuka: "Empfehlung von Sommelier",
    type_of_drinks: "Art des Getränks",
    premium_water: "Premium Wasser",
    description: "Beschreibung",
    price: "Preis",
    order: "Auftrag",
    table_number: "Tisch Nummer",
    note: "Anmerkung",
    glass_1: "Glas",
    bottle: "Flasche",
    order_successful: "Bestellung erfolgreich!",
    order_reviewed: "Bestellung überprüft!",
    mark_order: "Bestellung als geprüft markieren!",
    review: "Gesehen",
    order_num: "Bestellnummer:",
    table_num: "Tisch Nummer:",
    amount: "Menge:",
    review_orders: "Auftragsüberprüfung",
    password: "Passwort",
    view_your_orders: "Anzeigen Ihrer Bestellungen",
    view_all_orders: "Alle Bestellungen anzeigen",
    payment_method: "Zahlungsmethode",
    credit_card: "Kreditkarte",
    cash: "Bargeld",
    room: "Zimmer",
    choose_card: "Wählen Sie Karte",
    all_products: "Alle Produkte",
  },
  it: {
    cancel: "Annulla",
    add_note: "Aggiungi una nota",
    filters: " Filtri ",
    back: " Indietro ",
    winery: " Azienda vinicola ",
    region: " Regione ",
    alcohol: " Percentuale di alcol ",
    sort: " Ordinare ",
    legal: " Informazione legale ",
    menu: " Seleziona un menu ",
    products: " Nessun prodotto ",
    close: " Chiudi ",
    companyName: " Nome della ditta ",
    address: " Indirizzo ",
    complaints: " Il libro dei reclami è sul bar ",
    serving: " Metodo di servizio ",
    glass: " Sul vetro ",
    regions: " Regioni ",
    confirm: " Confermare ",
    clear: " Pulisci i filtri ",
    adInst: " Fare clic in un punto qualsiasi per chiudere l'annuncio ",
    all_wines: "Tutti i vini",
    on_glass: "Sul vetro",
    food_class: "Classificazione degli alimenti",
    wine: "Vini",
    search: "Ricerca",
    menu1: "Menù",
    preporuka: "Raccomandazione del sommelier",
    type_of_drinks: "Tipo di bevanda",
    premium_water: "Acqua premium",
    description: "Descrizione",
    price: "Prezzo",
    order: "Ordine",
    table_number: "Numero di tavolo",
    note: "Nota",
    glass_1: "Bicchiere",
    bottle: "Bottiglia",
    order_successful: "Ordine riuscito!",
    order_reviewed: "Ordine verificato!",
    mark_order: "Contrassegna l'ordine come revisionato!",
    review: "Visto",
    order_num: "Numero d'ordine:",
    table_num: "Numero tavolo:",
    amount: "Quantità",
    review_orders: "Revisione dell'ordine",
    password: "Parola d’ordine",
    view_your_orders: "Visualizza i tuoi ordini",
    view_all_orders: "Visualizza tutti gli ordini",
    payment_method: "Metodo di pagamento",
    credit_card: "Carta di credito",
    cash: "Contanti",
    room: "Camera",
    choose_card: "Scegli la carta",
    all_products: "Tutti i prodotti",
  },
  ru: {
    cancel: "Отмена",
    add_note: "Добавить заметку",
    filters: "Фильтры",
    back: " Назад ",
    winery: " Винодельня ",
    region: " Область ",
    alcohol: "Процент алкоголя ",
    sort: " виноград ",
    legal: " Легальная информация ",
    menu: " Выберите меню ",
    products: " Нет продуктов ",
    close: " близко ",
    companyName: " Название компании ",
    address: " Адрес ",
    complaints: "Книга жалоб находится в баре ",
    serving: "Метод обслуживания ",
    glass: " На стекле ",
    regions: " Регионы ",
    confirm: " Подтвердить ",
    clear: " Очистите фильтры ",
    adInst: " Щелкните в любом месте, чтобы закрыть объявление ",
    all_wines: "Все вина",
    on_glass: "На стекле",
    food_class: "Классификация продуктов питания",
    wine: "вина",
    search: "поиск",
    menu1: "Меню",
    preporuka: "Рекомендация сомелье",
    type_of_drinks: "Тип напитка",
    premium_water: "Премиум вода",
    description: "Описание",
    price: "цена",
    order: "Заказ",
    table_number: "Номер стола",
    note: "Замечание",
    glass_1: "Стекло",
    bottle: "Бутылка",
    order_successful: "Заказ выполнен!",
    order_reviewed: "Заказ рассмотрен!",
    mark_order: "Отметьте заказ как рассмотренный!",
    review: "Просмотрено",
    order_num: "Номер заказа:",
    table_num: "Номер таблицы:",
    amount: "количество:",
    review_orders: "Обзор заказов",
    password: "Пароль",
    view_your_orders: "Просмотр заказов",
    view_all_orders: "Просмотр всех заказов",
    payment_method: "Способ оплаты",
    credit_card: "Кредитная карта",
    cash: "Наличные",
    room: "Номер",
    choose_card: "Выберите карту",
    all_products: "Все продукты",
  },
  fr: {
    cancel: "Annuler",
    add_note: "Ajouter une note",
    filters: "Filtres",
    back: "Retour",
    winery: "Vignoble",
    region: "Région",
    alcohol: "Pourcentage d'alcool",
    sort: "Sorte",
    legal: "Information légale",
    menu: "Sélectionnez un menu",
    products: "Aucun produit",
    close: "Fermer",
    companyName: "Nom de l´entreprise",
    address: "Adresse",
    complaints: "Le livre des plaintes est sur le bar",
    serving: "Méthode de service",
    glass: "Au verre",
    regions: "Régions",
    confirm: "Confirmer",
    clear: "Nettoyez les filtres",
    adInst: "Cliquez n'importe où pour afficher le menu",
    // adInst: 'Cliquez n'importe où pour fermer l'annonce',
    all_wines: "Tous les vins",
    on_glass: "Au verre",
    food_class: "Reparticion des plats",
    wine: "Vins",
    search: "Chercher",
    menu1: "Menu",
    preporuka: "Recommandation du sommelier",
    type_of_drinks: "Types de boissons",
    premium_water: "Eau premium",
    description: "La description",
    price: "Prix",
    order: "Ordre",
    table_number: "Numéro de table",
    note: "Remarque",
    glass_1: "Verre",
    bottle: "Bouteille",
    order_successful: "Commande réussie!",
    order_reviewed: "Commande examinée!",
    mark_order: "Marquer la commande comme vérifiée!",
    review: "Vu",
    order_num: "Numéro de commande:",
    table_num: "Numéro de table:",
    amount: "Montant:",
    review_orders: "Examen des commandes",
    password: "Mot de passe",
    view_your_orders: "Afficher vos commandes",
    view_all_orders: "Afficher toutes les commandes",
    payment_method: "Mode de paiement",
    credit_card: "Carte de crédit",
    cash: "Liquide",
    room: "Chambre",
    choose_card: "Choisissez la carte",
    all_products: "Tous les produits",
  },
  pl: {
    cancel: "Anuluj",
    add_note: "Dodaj notatkę",
    filters: "Filtry",
    back: "Wróć",
    winery: "Winnica",
    region: "Region",
    alcohol: "Alkohol",
    sort: "Rodzaj",
    legal: "Informacje prawne",
    menu: "Menu",
    products: "Produkty",
    close: "Zamknij",
    companyName: "Nazwa firmy",
    address: "Adres",
    complaints: "Skargi",
    serving: "Serwowanie",
    glass: "Szklanka",
    regions: "Regiony",
    confirm: "Potwierdź",
    clear: "Wyczyść",
    adInst: "Instalacja reklamy",
    sulphits: "Siarczyny",
    alergens: "Alergeny",
    all_wines: "Wszystkie wina",
    on_glass: "Na kieliszki",
    food_class: "Klasa jedzenia",
    wine: "Wino",
    search: "Szukaj",
    menu1: "Menu 1",
    preporuka: "Rekomendacja",
    type_of_drinks: "Rodzaj napojów",
    premium_water: "Woda premium",
    description: "Opis",
    price: "Cena",
    order: "Zamówienie",
    table_number: "Numer stołu",
    note: "Notatka",
    glass_1: "Szklanka 1",
    bottle: "Butelka",
    order_successful: "Zamówienie zakończone sukcesem",
    order_reviewed: "Zamówienie sprawdzone",
    mark_order: "Oznacz zamówienie",
    review: "Recenzja",
    order_num: "Numer zamówienia",
    table_num: "Numer stolika",
    amount: "Ilość",
    review_orders: "Przegląd zamówień",
    password: "Hasło",
    view_your_orders: "Zobacz swoje zamówienia",
    view_all_orders: "Zobacz wszystkie zamówienia",
    payment_method: "Metoda płatności",
    credit_card: "Karta kredytowa",
    cash: "Gotówka",
    room: "Pokój",
    choose_card: "Wybierz kartę",
    all_products: "Wszystkie produkty",
  },
  sa: {
    cancel: "يلغي",
    add_note: "أضف ملاحظة",
    filters: "المرشحات",
    back: "عودة",
    winery: "مصنع الخمرة",
    region: "منطقة",
    alcohol: "منطقة",
    sort: "نسبة الكحول",
    legal: "المعلومات القانونية",
    menu: "حدد قائمة",
    products: "لا توجد منتجات",
    close: "قريب",
    companyName: "اسم الشركة",
    address: "عنوان",
    complaints: "كتاب الشكوى موجود على البار",
    serving: "طريقة التقديم",
    glass: "على الزجاج",
    regions: "المناطق",
    confirm: "تؤكد",
    clear: "نظف المرشحات",
    adInst: "انقر في أي مكان لعرض القائمة",
    // adInst: 'انقر في أي مكان لإغلاق الإعلان',
    all_wines: "كل أنواع النبيذ",
    on_glass: "بالزجاج",
    food_class: "توزيع المواد الغذائية",
    wine: "نبيذ",
    search: "بحث",
    menu1: "قائمة طعام",
    preporuka: "توصية السقاية",
    type_of_drinks: "أنواع المشروبات",
    premium_water: "مياه ممتازة",
    description: "وصف",
    price: "السعر",
    order: "ترتيب",
    table_number: "رقم الطاولة",
    note: "ملاحظة",
    glass_1: "زجاج",
    bottle: "زجاجة",
    order_successful: "الطلب ناجح!",
    order_reviewed: "تمت مراجعة الطلب!",
    mark_order: "وضع علامة على الطلب كمراجع!",
    review: "ينظر",
    order_num: "رقم الأمر:",
    table_num: "رقم الطاولة:",
    amount: "كمية",
    review_orders: "مراجعة الأمر",
    password: "شعار",
    view_your_orders: "عرض الطلبات",
    view_all_orders: "عرض كافة الطلبات",
    payment_method: "طريقة الدفع او السداد",
    credit_card: "بطاقة ائتمان",
    cash: "السيولة النقدية",
    room: "غرفة",
    choose_card: "اختر بطاقة",
    all_products: "جميع المنتجات",
  },
};

const i18n = new VueI18n({
  locale: "hr",
  fallbackLocale: "en",
  messages,
});

import Pusher from "pusher-js";
import VueEcho from "vue-echo-laravel";

Pusher.logToConsole = false;

import Echo from "laravel-echo";

const EchoInstance = new Echo({
  broadcaster: "pusher",
  key: "W64B697V",
  cluster: "mt1",
  enableStats: false,
  forceTLS: true,
  wsHost: "api.master-fb.com",
  enabledTransports: ["ws", "wss"],
});

Vue.use(VueEcho, EchoInstance);

new Vue({
  router,
  i18n,
  store: store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
